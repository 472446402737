import React from "react"
import styled from "styled-components"

const SubHero = ({ Headline }) => {
  return (
    <section className="mb-16 flex items-center bg-primary py-48">
      <div className="container mx-auto px-6 flex">
        <div className="flex flex-col items-center h-full w-full mt-12">
          <h1 className="text-white text-3xl lg:text-5xl leading-tight text-center mb-3 leading-relaxed">
            {Headline}
          </h1>
        </div>
      </div>
    </section>
  )
}

export default SubHero
