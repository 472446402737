import React from "react"

export const Section = ({ children, ID }) => {
  return (
    <section id={ID} className="my-24">
      {children}
    </section>
  )
}

export const Wrapper = ({ children }) => {
  return <div className="container mx-auto px-6">{children}</div>
}
