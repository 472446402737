import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import SubHero from "../components/ui/subHero"
import { Wrapper, Section } from "../components/container"

const NotFoundPage = () => (
  <Layout>
    <Seo title="404 : Not found" />
    <SubHero Headline="404! " />
    <Section>
      <Wrapper>
        <h1>Hier wolltest du bestimmt nicht hin.</h1>
        <p>
          Der Link dem du gefolgt bist existiert leider nicht. Bitte geh zurück
          zur Startseite.
        </p>
      </Wrapper>
    </Section>
  </Layout>
)

export default NotFoundPage
